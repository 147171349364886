<template>
  <q-dialog v-model="isOpen" >
    <q-card style="max-width: 100vw">
      <q-card-section class="row">
        <div class="text-h6 text-center">
          {{ $t('Batch') }}
        </div>

        <q-space />

        <q-btn
            color="transparent"
            text-color="dark"
            size="sm"
            icon="close"
            no-caps
            unelevated
            @click="hide"
        />
      </q-card-section>

      <q-card-section>
          <expected-inventory-where-table :item="item" />
      </q-card-section>

    </q-card>
  </q-dialog>
</template>

<script>
// Components

// Vuex
import { mapGetters } from 'vuex'
import ExpectedInventoryWhereTable from "@/apps/app/components/tables/ExpectedInventoryWhereTable.vue";

export default {
  name: 'ExpectedInventoryWhere',
  components: {
    ExpectedInventoryWhereTable,
  },
  data () {
    return {
      isOpen: false,
      item: null
    }
  },
  computed: {
    ...mapGetters([
      'isClient'
    ])
  },
  methods: {
    open (item) {
      this.item = item
      this.isOpen = true
    },
    hide () {
      this.isOpenFilter = false
      this.isOpen = false
    }
  }
}
</script>
