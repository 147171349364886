<template>
  <q-card>
    <q-card-section class="row border-bottom items-center full-width q-py-xs q-pl-none">
      <q-legend :label="$t('Products')" />

      <search
          dense
          autoset
          is-expandable
          @submit="handleSearch"
      />

      <q-space />


      <q-btn
          color="dark"
          text-color="white"
          :label="$t('Refresh')"
          size="sm"
          class="q-mr-sm"
          no-caps
          unelevated
          @click="refreshItems"
      />

    </q-card-section>


    <q-card-section class="q-ma-none q-pa-none">
      <q-table
          row-key="id"
          :rows-per-page-label="$t('Rows per page')"
          :rows="items"
          :columns="columns"
          v-model:pagination="pagination"
          :loading='isLoading'
          :table-header-class="$q.dark.isActive ? '' : (options.style && options.style.header) || ''"
          virtual-scroll
          binary-state-sort
          flat
          @request="onRequest"
      >
        <template v-slot:loading>
          <q-inner-loading
              showing
              color="primary"
          />
        </template>

        <template v-slot:body="props">
            <expected-inventory-where-table-row :data="props"/>
        </template>
          <template v-slot:bottom-row>
              <q-tr>
                  <q-td class="border-top border-right text-center" key="number">
                      <strong>
                          {{ $t('Total') }}
                      </strong>
                  </q-td>

                  <q-td class="border-top" key="date"/>

                  <q-td class="border-top" key="image"/>

                  <q-td class="border-top" key="name"/>

                  <q-td class="border-top" key="expectedItems">
                      <strong>
                          {{ totalExpected }}
                      </strong>
                  </q-td>

                  <q-td class="border-top"  key="quantityReal">
                      <strong>
                          {{ received }}
                      </strong>
                  </q-td>

                  <q-td class="border-top" key="blocked" >
                      <strong>
                          {{ blocked }}
                      </strong>
                  </q-td>

                  <q-td class="border-top" key="booked">
                      <strong>
                          {{ booked }}
                      </strong>
                  </q-td>
                  <q-td class="border-top" key="defected">
                      <strong>
                          {{ defected }}
                      </strong>
                  </q-td>
                  <q-td class="border-top" key="quantityPlace">
                      <strong>
                          {{ onHand }}
                      </strong>
                  </q-td>
                  <q-td class="border-top"  key="quantityShipped">
                      <strong>
                          {{ shipped }}
                      </strong>
                  </q-td>
              </q-tr>
          </template>
      </q-table>
    </q-card-section>
  </q-card>
</template>

<script>
// Vuex
import {mapActions, mapGetters, mapMutations} from 'vuex'

// Components
import Search from '../../components/search/Search.vue'

// Mixins
import TableMixin from '../../components/global/TableMixin'


// Utils
import { buildQuery } from '../../utils/query-utils'
import ExpectedInventoryWhereTableRow from "@/apps/app/components/tables/ExpectedInventoryWhereTableRow.vue";

export default {
  name: 'ExpectedInventoryWhereTable',
  components: {
    ExpectedInventoryWhereTableRow,
    Search,
  },
  mixins: [
    TableMixin
  ],
  props: {
    options: {
      type: Object,
      default () {
        return {
          filters: []
        }
      }
    },
    item: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      isOpen: false,
      items: [],
      isLoading: false,
      totalExpected: 0,
      totalReal: 0,
      totalPlace: 0,
      totalShipped: 0,
      blocked: 0,
      booked: 0,
      defected: 0,
      onHand: 0,
      shipped: 0,
      received: 0,
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      columns: [
        {
          label: '#',
          name: 'number',
          align: 'left'
        },
        {
          label: this.$t('Date'),
          name: 'date',
          align: 'center'
        },
        {
          label: this.$t('Image'),
          name: 'image',
          align: 'left'
        },
        {
          label: this.$t('Name'),
          name: 'name',
          align: 'left'
        },
        {
          label: this.$t('Expected Items'),
          name: 'expectedItems',
          align: 'left'
        },
        {
          label: this.$t('Quantity Received'),
          name: 'quantityReal',
          align: 'left'
        },
        {
          label: this.$t('Blocked'),
          name: 'blocked',
          align: 'left'
        },
        {
          label: this.$t('Booked'),
          name: 'booked',
          align: 'left'
        },
        {
          label: this.$t('Defected'),
          name: 'defected',
          align: 'left'
        },
        {
          label: this.$t('On Hand'),
          name: 'quantityPlace',
          align: 'left',
        },
        {
          label: this.$t('Sent'),
          name: 'quantityShipped',
          align: 'left'
        },
      ],
    }
  },
  computed: {
    ...mapGetters([
      'isClient',
      'appOptions',
      'user',
      'isSupervisior',
      'isAdministrator',
      'appOptions',
    ]),
  },
  mounted () {
    this.loadDefaultItems()
  },
  methods: {
    ...mapActions([
    ]),
    ...mapMutations([
    ]),
    refreshItems () {
      return this.onRequest({
        pagination: {
          forceReload: true
        }
      })
    },
    loadDefaultItems () {
      this.totalExpected = 0
      this.totalReal = 0
      this.totalShipped = 0
      this.totalPlace = 0
      return this.onRequest({ pagination: { page: 1, rowsPerPage: 25 } })
    },
    getRowImage(row) {
      return row._embedded.productOffer && row._embedded.productOffer.image ? row._embedded.productOffer.image : this.fallbackImage;
    },
    onImageLoadFailure (e) {
      e.target.src = 'assets/img/fallback-image/package.png'
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)

      if (query.search && query.search[query.search.length - 1] !== '*' && query.search[query.search.length - 2] !== ':' && !query.search.includes('%')) {
        query.search += query.search[query.search.length - 1] === ':'
          ? '*'
          : ':*'
      }
      query['group'] = [{
        field: 'productOffer',
        type: 'field',
      }];

      query['order-by'] = [{
        field: 'shop',
        direction: 'asc',
        type: 'field'
      }]

      if(this.item && this.item._embedded && this.item._embedded.productOffer) {
        query.filter = [{type: 'eq', field: 'productOffer', value: this.item._embedded.productOffer.id}]
      }
       query['fields'] = ['productOffer', 'quantityExpected', 'quantityReal', 'quantityPlace', 'quantityShipped', 'created']

      if (this.isSupervisior || this.isAdministrator) {
        if (query.filter) {
          query.filter.push({type: 'eq', field: 'state', value: 'new'})
          query.filter.push({
            type: 'jsonboperator',
            subfield: 'storage-acceptance-item-user-created',
            field: 'eav',
            subtype: 'isnotnull',
            native: true
          })
        } else {
          query.filter = [
            {type: 'eq', field: 'state', value: 'new'},
            {
              type: 'jsonboperator',
              subfield: 'storage-acceptance-item-user-created',
              field: 'eav',
              subtype: 'isnotnull',
              native: true
            }
          ]
        }
      } else {
        if (query.filter) {
          query.filter.push({type: 'eq', field: 'state', value: 'new'})
          query.filter.push({
            type: 'jsonboperator',
            subfield: 'storage-acceptance-item-user-created',
            field: 'eav',
            value: this.user.id,
            subtype: 'eq',
            native: true
          })
        } else {
          query.filter = [
            {type: 'eq', field: 'state', value: 'new'},
            {
              type: 'jsonboperator',
              subfield: 'storage-acceptance-item-user-created',
              field: 'eav',
              value: this.user.id,
              subtype: 'eq',
              native: true
            }
          ]
        }
      }
      if (this.customFilter) {
        query.filter.push(this.customFilter)
      }
      this.isLoading = true
      return this.$service.acceptanceItem.getAll(query)
        .then(({ items, totalPages, page, totalItems }) => {
          this.pagination = {
            ...this.pagination,
            page: query.page || 1,
            rowsNumber: totalItems
          }
          if(items && items.length >0) {
            const productOffer = items[0]
            if(productOffer?._embedded?.productOffer?.items) {
              let items = productOffer._embedded.productOffer.items
              items.forEach(e => {
                switch (e.state) {
                  case 'new':
                    this.received = e.count
                    break;
                  case 'booked':
                    this.booked = e.count
                    break;
                  case 'blocked':
                    this.blocked = e.count
                    break;
                  case 'normal':
                    this.onHand = e.count
                    break;
                  case 'shipped':
                    this.shipped = e.count
                    break;
                }
              })
            }
            items.forEach(e=>{
              this.totalExpected += e.quantityExpected
              this.totalReal += e.quantityReal
                this.totalShipped += e.quantityShipped
              this.totalPlace += e.quantityPlace
            })
          }
          this.items = items
        })
        .finally( () => {
          this.isLoading = false
        })
    },
    handleSearch (search) {
      return this.onRequest({ pagination: { search, page: 1 } })
    },
  }
}
</script>
