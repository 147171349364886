<template>
  <q-tr
      :props="data"
      class="clickable"
      @dblclick="onRowDblClick(data.row)"
  >
    <q-td
        key="number"
        :props="data"
        auto-width
    >
        {{ data.rowIndex + 1 }}
    </q-td>


    <q-td
        key="image"
        :props="data"
        auto-width
    >

      <div class="text-center">
        <img
            :src="getRowImage(data.row)"
            style="width: 80px; height: 70px; object-fit: contain;"
            @error="onImageLoadFailure"
        >
      </div>

        <div class="text-center">
          <q-btn
              :label="$t(model._embedded?.productOffer?.type || '')"
              style="min-width: 100px;"
              color="light-blue-9"
              icon-right="visibility"
              size="sm"
              class="q-mt-sm block full-width text-capitalize"
              no-caps
              v-if="model._embedded?.productOffer?.id"
              @click="open"
          />
        </div>
        <div class="text-center">
            <q-btn
                :label="$t('Batch')"
                style="min-width: 100px;"
                color="light-blue-9"
                size="sm"
                class="q-my-sm block full-width text-capitalize"
                no-caps
                @click="openBatch"
            />
<!--          <q-btn-->
<!--              :label="$t('Batch')"-->
<!--              style="min-width: 100px;"-->
<!--              color="light-blue-9"-->
<!--              size="sm"-->
<!--              class="q-my-sm block full-width text-capitalize"-->
<!--              no-caps-->
<!--              v-if="(model?.eav || {})['storage-acceptance-expected-item-ASIN']"-->
<!--              @click="handleAddBatchFilter"-->
<!--          />-->
        </div>
    </q-td>
    <q-td
        key="name"
        :props="data"
        auto-width
    >
      <div class="text-center" style="width: 300px; word-wrap: break-word; white-space: normal !important;">
        <span v-if="model._embedded?.productOffer?.name">
          {{model._embedded.productOffer.name}}
        </span>
      </div>
    </q-td>

    <q-td
            key="date"
            :props="data"
            auto-width
            style="white-space: pre-wrap !important; max-width: 100px;"
    >
        <p class="q-ma-none text-caption">
            {{ $moment(typeof data.row.created === 'object' ? data.row.created.date : data.row.created).format(appOptions.formats.fullDate) }}
        </p>
    </q-td>


      <q-td
          key="upcs"
          :props="data"
          auto-width
      >

        <div>
          <q-select
              v-model="(model?.eav || {})['storage-acceptance-expected-item-upcs']"
              :options="this.options"
              option-value="key"
              option-label="value"
              :emit-value="true"
              menu-cover
              ref="mySelect3"
              close-popup-on-select
              style="min-width: 200px"
          >
            <template v-slot:no-option>
              <q-item clickable @click="addOptionDialog3 = true">
                <q-item-section>Add Option</q-item-section>
                <q-item-section side>
                    <q-icon name="add" color="primary"/>
                </q-item-section>
              </q-item>
            </template>
            <template v-slot:option="scope">
              <q-item :key="scope" v-close-popup clickable @click="handleEditEAV('eav', scope.label, 'storage-acceptance-expected-item-upcs')" class="cursor-pointer">
                <q-item-section>
                    {{ scope.label }}
                </q-item-section>
              </q-item>
            </template>
            <template v-slot:after-options>
              <q-item clickable @click="addOptionDialog3 = true">
                <q-item-section>Add Option</q-item-section>
                <q-item-section side>
                    <q-icon name="add" color="primary"/>
                </q-item-section>
              </q-item>
            </template>

            <q-dialog v-model="addOptionDialog3" persistent>
              <q-card>
                <q-card-section>
                  <q-input v-model="newOption3" label="Label"/>
                </q-card-section>
                <q-card-actions align="right">
                  <q-btn label="Cancel" @click="addOptionDialog3 = false"/>
                  <q-btn label="Add" color="primary" @click="handleEditEAV('eav', newOption3, 'storage-acceptance-expected-item-upcs')"/>
                </q-card-actions>
              </q-card>
            </q-dialog>
          </q-select>
        </div>
      </q-td>


    <q-td
        key="toDoType"
        :props="data"
        auto-width
    >
        <div class="q-pt-md" style="min-width: 100px">
          <q-select
              :model-value="(model?.eav || {})['storage-acceptance-expected-item-todo-type']"
              option-label="label"
              class="q-mb-sm"
              :options="typesTodo"
              size="sm"
              dense
              stack-label
              menu-props="label"
              @update:model-value="handleEditEAV('eav', $event.id, 'storage-acceptance-expected-item-todo-type')"
          />
        </div>
    </q-td>

    <q-td
        key="trackingNumber"
        :props="data"
        auto-width
    >
      <div style="min-width: 200px">
        <editable-text-input
            :value="model.trackingNumber"
            :dense="false"
            :update-function="newValue => handleEditEAV('trackingNumber', newValue)"
        ></editable-text-input>
      </div>
    </q-td>

    <q-td
        key="marketplace"
        :props="data"
        auto-width
    >
      <div>
        <q-select
            v-model="marketplaces[marketplace]"
            :options="marketplaceOptions"
            option-value="key"
            option-label="value"
            :emit-value="true"
            :disable="true"
            menu-cover
            ref="mySelect2"
            close-popup-on-select
            style="min-width: 200px"
        >
          <template v-slot:option="scope">
            <q-item :key="scope" v-close-popup clickable @click="handleEditEAV('eav', scope.opt.value, 'storage-acceptance-expected-item-marketplace')" class="cursor-pointer">
              <q-item-section>
                {{ scope.opt.label }}
              </q-item-section>
            </q-item>
          </template>
        </q-select>
      </div>
    </q-td>

    <q-td
        key="source"
        :props="data"
        auto-width
    >
      <div>
        <q-select
            v-model="sourceId"
            :options="optionsMSKU"
            option-value="key"
            option-label="value"
            :emit-value="true"
            menu-cover
            ref="mySelect2"
            :disable="true"
            close-popup-on-select
            style="min-width: 200px"
        >
          <template v-slot:option="scope">
            <q-item :key="scope" v-close-popup clickable @click="handleEditEAV('eav', scope.label, 'storage-acceptance-expected-item-MSKU')" class="cursor-pointer">
              <q-item-section>
                  {{ scope.label }}
              </q-item-section>
            </q-item>
          </template>
        </q-select>
      </div>
    </q-td>

    <q-td
        key="asin"
        :props="data"
        auto-width
    >
      <div class="text-center" style="min-width: 200px">
          <editable-text-input
                  :value="(model?.eav || {})['storage-acceptance-expected-item-ASIN']"
                  :dense="false"
                  :disabled="disableASIN"
                  :update-function="newValue => handleEditEAV('eav', newValue, 'storage-acceptance-expected-item-ASIN')"
          ></editable-text-input>
      </div>
<!--      <div>-->
<!--          <q-select-->
<!--              v-model="(model?.eav || {})['storage-acceptance-expected-item-ASIN']"-->
<!--              :options="this.options"-->
<!--              option-value="key"-->
<!--              option-label="value"-->
<!--              :emit-value="true"-->
<!--              :disable="disableASIN"-->
<!--              menu-cover-->
<!--              ref="mySelect"-->
<!--              close-popup-on-select-->
<!--              style="min-width: 200px"-->
<!--          >-->
<!--              <template v-slot:no-option>-->
<!--                  <q-item clickable @click="addOptionDialog = true">-->
<!--                      <q-item-section>Add Option</q-item-section>-->
<!--                      <q-item-section side>-->
<!--                          <q-icon name="add" color="primary"/>-->
<!--                      </q-item-section>-->
<!--                  </q-item>-->
<!--              </template>-->
<!--              <template v-slot:option="scope">-->
<!--                  <q-item :key="scope" v-close-popup clickable @click="handleEditEAV('eav', scope.label, 'storage-acceptance-expected-item-ASIN')" class="cursor-pointer">-->
<!--                      <q-item-section>-->
<!--                          {{ scope.label }}-->
<!--                      </q-item-section>-->
<!--                  </q-item>-->
<!--              </template>-->
<!--              <template v-slot:after-options>-->
<!--                  <q-item clickable @click="addOptionDialog = true">-->
<!--                      <q-item-section>Add Option</q-item-section>-->
<!--                      <q-item-section side>-->
<!--                          <q-icon name="add" color="primary"/>-->
<!--                      </q-item-section>-->
<!--                  </q-item>-->
<!--              </template>-->

<!--              <q-dialog v-model="addOptionDialog" persistent>-->
<!--                  <q-card>-->
<!--                      <q-card-section>-->
<!--                          <q-input v-model="newOption" label="Label"/>-->
<!--                      </q-card-section>-->
<!--                      <q-card-actions align="right">-->
<!--                          <q-btn label="Cancel" @click="addOptionDialog = false"/>-->
<!--                          <q-btn label="Add" color="primary" @click="handleEditEAV('eav', newOption, 'storage-acceptance-expected-item-ASIN')"/>-->
<!--                      </q-card-actions>-->
<!--                  </q-card>-->
<!--              </q-dialog>-->
<!--          </q-select>-->

<!--      </div>-->
    </q-td>

    <q-td
        key="msku"
        :props="data"
        auto-width
    >
      <div>
        <q-select
            v-model="(model?.eav || {})['storage-acceptance-expected-item-MSKU']"
            :options="optionsMSKU"
            option-value="key"
            option-label="value"
            :emit-value="true"
            :disable="handleMskuDisable"
            menu-cover
            ref="mySelect2"
            close-popup-on-select
            style="min-width: 200px"
        >
          <template v-slot:no-option>
            <q-item v-if="currentFBA.label" v-close-popup clickable @click="handleEditEAV('eav', currentFBA.label, 'storage-acceptance-expected-item-MSKU')" class="cursor-pointer">
                <q-item-section>
                    {{currentFBA.label}}
                </q-item-section>
            </q-item>

            <q-item clickable @click="addOptionDialog2 = true">
              <q-item-section>Add Option</q-item-section>
              <q-item-section side>
                  <q-icon name="add" color="primary"/>
              </q-item-section>
            </q-item>
          </template>

          <template v-slot:before-options>
              <q-item v-if="currentFBA.label" v-close-popup clickable @click="handleEditEAV('eav', currentFBA.label, 'storage-acceptance-expected-item-MSKU')" class="cursor-pointer">
                  <q-item-section>
                      {{currentFBA.label}}
                  </q-item-section>
              </q-item>
          </template>

          <template v-slot:option="scope">
            <q-item :key="scope" v-close-popup clickable @click="handleEditEAV('eav', scope.label, 'storage-acceptance-expected-item-MSKU')" class="cursor-pointer">
              <q-item-section>
                  {{ scope.label }}
              </q-item-section>
            </q-item>
          </template>
          <template v-slot:after-options>
            <q-item clickable @click="addOptionDialog2 = true">
              <q-item-section>Add Option</q-item-section>
              <q-item-section side>
                  <q-icon name="add" color="primary"/>
              </q-item-section>
            </q-item>
          </template>

          <q-dialog v-model="addOptionDialog2" persistent>
            <q-card>
              <q-card-section>
                <q-input v-model="newOption2.label" label="Label"/>
              </q-card-section>
              <q-card-actions align="right">
                <q-btn label="Cancel" @click="addOptionDialog2 = false"/>
                <q-btn label="Add" color="primary" :disable="!newOption2.label" @click="addNewOption"/>
              </q-card-actions>
            </q-card>
          </q-dialog>
        </q-select>
      </div>
    </q-td>


    <q-td
        key="bundleDetails"
        :props="data"
        auto-width
    >
      <div class="text-center" style="min-width: 200px">
        <editable-textarea-input
            :value="(model?.eav || {})['storage-acceptance-expected-item-bundle-comment']"
            :outlined="true"
            :update-function="newValue => handleEditEAV('eav', newValue, 'storage-acceptance-expected-item-bundle-comment')"
        ></editable-textarea-input>

      </div>
    </q-td>

    <q-td
        key="bundleType"
        :props="data"
        auto-width
    >
      <div>
        <q-select
            :model-value="(model?.eav || {})['storage-acceptance-expected-item-bundle-type']"
            option-label="label"
            class="q-mb-sm"
            :options="types"
            size="sm"
            dense
            stack-label
            @update:model-value="handleEditEAV('eav', $event.id, 'storage-acceptance-expected-item-bundle-type')"
        />

      </div>
    </q-td>


    <q-td
        key="toDoNotes"
        :props="data"
        auto-width
    >
      <div class="text-center" style="min-width: 200px">
        <editable-textarea-input
            :value="(model?.eav || {})['storage-acceptance-expected-item-todo-notes']"
            :outlined="true"
            :update-function="newValue => handleEditEAV('eav', newValue, 'storage-acceptance-expected-item-todo-notes')"
        ></editable-textarea-input>

      </div>
    </q-td>

    <q-td
        key="extId"
        :props="data"
        auto-width
    >
      <div class="text-center" style="min-width: 200px">
        <editable-text-input
            :value="(model?.eav || {})['storage-acceptance-expected-item-supplier-order-number']"
            :dense="false"
            :update-function="newValue => handleEditEAV('eav', newValue, 'storage-acceptance-expected-item-supplier-order-number')"
        ></editable-text-input>
      </div>
    </q-td>


    <q-td
        key="extDate"
        :props="data"
        auto-width
    >
        <div class="text-center" style="min-width: 200px">
            <form-builder :schema="schema2"/>
        </div>
    </q-td>

    <q-td
        key="supplies"
        :props="data"
        auto-width
    >
      <div class="text-center" style="min-width: 200px">
        <editable-text-input
            :value="(model?.eav || {})['storage-acceptance-expected-item-supplies-il']"
            :dense="false"
            :update-function="newValue => handleEditEAV('eav', newValue, 'storage-acceptance-expected-item-supplies-il')"
        ></editable-text-input>

      </div>
    </q-td>


    <q-td
        key="cost"
        :props="data"
        style="width: 80px; white-space: pre-wrap !important;"
    >
        <div class="q-pt-md" style="min-width: 80px">
            <editable-input-field
                    :value="model.price"
                    :update-function="newValue => handleEditEAV('price', newValue)"
                    :rules="[
            (val) => /^[0-9]\d*$/.test(val),
          ]"
            ></editable-input-field>


        </div>
    </q-td>


    <q-td
        key="expectedItems"
        :props="data"
        auto-width
    >
      <div class="q-pt-md" style="min-width: 200px">
        <editable-input-field
            :value="model.quantityExpected"
            :update-function="newValue => handleEditEAV('count', newValue)"
            :rules="[
              (val) => /^[0-9]\d*$/.test(val),
            ]"
        ></editable-input-field>

      </div>

    </q-td>

      <q-td
          key="quantityReal"
          :props="data"
          auto-width
      >
        <div class="q-pt-md" style="min-width: 200px">
          <strong>
              {{received}}
          </strong>
        </div>


      </q-td>

      <q-td
          key="blocked"
          :props="data"
          auto-width
      >
        <div class="q-pt-md" style="min-width: 200px">
          <strong>
              {{ blocked }}
          </strong>
        </div>
      </q-td>

      <q-td
          key="booked"
          :props="data"
          auto-width
      >
        <div class="q-pt-md" style="min-width: 200px">
          <strong>
              {{ booked }}
          </strong>
        </div>
      </q-td>

      <q-td
          key="defected"
          :props="data"
          auto-width
      >
        <div class="q-pt-md" style="min-width: 200px">
          <strong>
              {{ defected }}
          </strong>
        </div>
      </q-td>


      <q-td
          key="quantityPlace"
          :props="data"
          auto-width
      >
        <div class="q-pt-md" style="min-width: 200px">
          <strong>
              {{ onHand }}
          </strong>
        </div>

      </q-td>
      <q-td
          key="quantityShipped"
          :props="data"
          auto-width
      >
        <div class="q-pt-md" style="min-width: 200px">
          <strong>
              {{ shipped }}
          </strong>
        </div>

      </q-td>

      <q-td
          key="action"
          :props="data"
          auto-width
      >
        <q-btn
            color="danger"
            text-color="white"
            :label="$t('Delete')"
            @click="handleEditEAV('state', 'deleted')"
        />
        <q-btn
            :label="$t('Copy')"
            color="light-blue-9"
            class="q-mt-sm block full-width"
            @click="handleCopy"
        />

      </q-td>
  </q-tr>

    <expected-inventory-where ref="expectedWhere"/>
</template>

<script>
import _ from 'lodash'
import EditableInputField from "@/apps/app/components/delivery-services/EditableInputField.vue";
import EditableTextareaInput from "@/apps/app/components/delivery-services/EditableTextareaInput.vue";
import EditableTextInput from "@/apps/app/components/delivery-services/EditableTextInput.vue";
import {mapGetters} from "vuex";
import ExpectedInventoryWhere from "@/apps/app/components/modals/ExpectedInventoryWhere.vue";

export default {
  name: 'ExpectedItemsRowTableView',
  components: {
    ExpectedInventoryWhere,
    EditableTextInput,
    EditableTextareaInput,
    EditableInputField
  },
  emits: ['open', 'add-custom-filter','delete', 'copy'],
  props: {
    data: {
      type: Object,
      required: true,
      default () {
        return null
      }
    }
  },
  data() {
    return {
      fallbackImage: 'assets/img/fallback-image/package.png',
      marketplace: '',
      bundles: '',
      addOptionDialog: false,
      addOptionDialog2: false,
      addOptionDialog3: false,
      options: this.data.row?._embedded?.productOffer?.barcodes,
      optionsMSKU: [],
      marketplaces: {
      'A2Q3Y263D00KWC': 'Brazil',
      'A2EUQ1WTGCTBG2': 'Canada',
      'A1AM78C64UM0Y8': 'Mexico',
      'ATVPDKIKX0DER': 'US',
      'A2VIGQ35RCS4UG': 'United Arab Emirates (U.A.E.)',
      'A1PA6795UKMFR9': 'Germany',
      'ARBP9OOSHTCHU': 'Egypt',
      'A1RKKUPIHCS9HS': 'Spain',
      'A13V1IB3VIYZZH': 'France',
      'AMEN7PMS3EDWL': 'Belgium',
      'A1F83G8C2ARO7P': 'UK',
      'A21TJRUUN4KGV': 'India',
      'APJ6JRA9NG5V4': 'Italy',
      'A1805IZSGTT6HS': 'Netherlands',
      'A1C3SOZRARQ6R3': 'Poland',
      'A17E79C6D8DWNP': 'Saudi Arabia',
      'A2NODRKZP88ZB9': 'Sweden',
      'A33AVAJ2PDY3EV': 'Turkey',
      'A19VAU5U5O7RUS': 'Singapore',
      'A39IBJ37TRP1C6': 'Australia',
      'A1VC38T7YXB528': 'Japan',
      'A1MQXOICRS2Z7M': 'SI CA Prod Marketplace - A1MQXOICRS2Z7M',
      'A2ZV50J4W1RKNI': 'Non-Amazon - A2ZV50J4W1RKNI',
      'A3H6HPSLHAK3XG': 'Amazon.com.mx - A1AM78C64',
      'AHRY1CZE9ZY4H': 'Amazon.com Invoicing Shadow Marketplace - AHRY1CZE9ZY4H',
      },
      marketplaceOptions: [
        {
          label: 'Brazil',
          value: 'A2Q3Y263D00KWC'
        },
        {
          label: 'Canada',
          value: 'A2EUQ1WTGCTBG2'
        },
        {
          label: 'Mexico',
          value: 'A1AM78C64UM0Y8'
        },
        {
          label: 'US',
          value: 'ATVPDKIKX0DER'
        },
        {
          label: 'United Arab Emirates (U.A.E.)',
          value: 'A2VIGQ35RCS4UG'
        },
        {
          label: 'Germany',
          value: 'A1PA6795UKMFR9'
        },
        {
          label: 'Egypt',
          value: 'ARBP9OOSHTCHU'
        },
        {
          label: 'Spain',
          value: 'A1RKKUPIHCS9HS'
        },
        {
          label: 'France',
          value: 'A13V1IB3VIYZZH'
        },
        {
          label: 'Belgium',
          value: 'AMEN7PMS3EDWL'
        },
        {
          label: 'UK',
          value: 'A1F83G8C2ARO7P'
        },
        {
          label: 'India',
          value: 'A21TJRUUN4KGV'
        },
        {
          label: 'Italy',
          value: 'APJ6JRA9NG5V4'
        },
        {
          label: 'Netherlands',
          value: 'A1805IZSGTT6HS'
        },
        {
          label: 'Poland',
          value: 'A1C3SOZRARQ6R3'
        },
        {
          label: 'Saudi Arabia',
          value: 'A17E79C6D8DWNP'
        },
        {
          label: 'Sweden',
          value: 'A2NODRKZP88ZB9'
        },
        {
          label: 'Turkey',
          value: 'A33AVAJ2PDY3EV'
        },
        {
          label: 'Singapore',
          value: 'A19VAU5U5O7RUS'
        },
        {
          label: 'Australia',
          value: 'A39IBJ37TRP1C6'
        },
        {
          label: 'Japan',
          value: 'A1VC38T7YXB528'
        },
        {
          label: 'SI CA Prod Marketplace - A1MQXOICRS2Z7M',
          value: 'A1MQXOICRS2Z7M'
        },
        {
          label: 'Non-Amazon - A2ZV50J4W1RKNI',
          value: 'A2ZV50J4W1RKNI'
        },
        {
          label: 'Amazon.com.mx - A1AM78C64',
          value: 'A3H6HPSLHAK3XG'
        },
        {
          label: 'Amazon.com Invoicing Shadow Marketplace - AHRY1CZE9ZY4H',
          value: 'AHRY1CZE9ZY4H'
        },
      ],
      currentFBA: { key: '', label: '' },
      sourceId: '',
      newOption: '',
      newOption2:  { key: '', label: '' },
      newOption3: '',
      blocked: 0,
      booked: 0,
      defected: 0,
      onHand: 0,
      shipped: 0,
      received: 0,
      todo: [],
      model: this.data.row,
      types: [
        { id: 'pre_bundling', label: 'Pre Bundling' },
        { id: 'for_bundling', label: 'For Bundling' },
      ],
      typesTodo: [
        { id: 'fba', label: 'FBA' },
        { id: 'fbm', label: 'FBM' },
        { id: 'return', label: 'Return' },
        { id: 'disposal', label: 'Disposal'},
        { id: 'other', label: 'Other'}
      ],
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'appOptions',
    ]),
    disableASIN () {
      if((this.model?.eav || {})['storage-acceptance-expected-item-ASIN'] || !this.data.row?._embedded?.productOffer ) {
        return true
      }
      return false
    },
    handleMskuDisable () {
      if((this.model?.eav || {})['storage-acceptance-expected-item-todo-type']) {
        if ((this.model?.eav || {})['storage-acceptance-expected-item-todo-type'] === 'fba') {
          return false
        }
        if ((this.model?.eav || {})['storage-acceptance-expected-item-todo-type'] === 'fbm') {
          return false
        }
      }
      return true
    },
    schema2 () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'date',
                label: '',
                field: 'extDate',
                value: (this.model?.eav || {})['storage-acceptance-expected-item-purchase-date'],
                class: 'bg-white',
                dense: false,
                styleClasses: 'bg-white',
                wrapperStyleClasses: 'col-12 q-pa-xs',
                get (extDate) {
                  return extDate && typeof extDate === 'object'
                    ? extDate.date
                    : extDate
                },
                onChange: (extDate) => {
                  this.hasChange = true
                  this.handleEditEAV('eav', extDate, 'storage-acceptance-expected-item-purchase-date')
                  // this.updateAcceptance({ extDate })
                }
              },
            ]
          }
        ]
      }
    },
    schema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'tag',
                label: '',
                wrapperStyleClasses: 'col-12 col-sm-12 q-pa-xs',
                field: 'todo',
                value: this.todo,
                onSubmit: (value) => {
                  if (!value) {
                    return
                  }

                  if (this.acceptance && this.acceptance.docExtended) {
                    this.updateAcceptance({ docExtended: [...this.acceptance.docExtended, value] })

                    this.$emit('change', this.acceptance)
                  }
                },
                onRemove: (value) => {
                  this.updateAcceptance({ docExtended: this.acceptance.docExtended.filter(x => x !== value) })
                  this.$emit('change', this.acceptance)
                },
                btnStyleClasses: 'q-pl-sm'
              }
            ]
          }
        ]
      }
    }
  },
  watch: {
    data: {
      handler (value) {
        this.model = this.data.row
      },
      deep: true
    },
  },
  mounted() {
    this.marketplace = this.data.row?._embedded?.productOffer?.eav['integrations-amazon-offer-marketplace'] ? this.data.row._embedded.productOffer.eav['integrations-amazon-offer-marketplace'] : 'ATVPDKIKX0DER'
    this.model = _.cloneDeep(this.data.row)
    if ( this.data.row?._embedded?.productOffer?.items && this.data.row?._embedded?.productOffer?.items.length > 0 ) {
      let items = this.data.row._embedded.productOffer.items
      items.forEach(e => {
        switch (e.state) {
          case 'new':
            this.received = e.count
            break;
          case 'booked':
            this.booked = e.count
            break;
          case 'blocked':
            this.blocked = e.count
            break;
          case 'normal':
            this.onHand = e.count
            break;
          case 'shipped':
            this.shipped = e.count
            break;
        }
      })
    }
    if((this.model?.eav || {})['storage-acceptance-expected-item-todo-type']) {
      if ((this.model?.eav || {})['storage-acceptance-expected-item-todo-type'] === 'fba') {
        this.getMsku(this.marketplace)
        this.handleCurrentFBA('fba')
      }
      if ((this.model?.eav || {})['storage-acceptance-expected-item-todo-type'] === 'fbm') {
        this.getMsku(this.marketplace)
        this.handleCurrentFBA('fbm')
      }
    }
  },
  methods: {
  handleCurrentFBA () {
      if(this.sourceId && this.marketplace) {
        if ((this.model?.eav || {})['storage-acceptance-expected-item-todo-type'] === 'fba' && this.data.row._embedded.productOffer.eav) {
          this.currentFBA.key = this.sourceId
          this.currentFBA.label = this.data.row._embedded.productOffer.eav['integrations-amazon-offer-sku-fba'][this.marketplace][this.sourceId]
          return this.currentFBA.label
        }
        else if ((this.model?.eav || {})['storage-acceptance-expected-item-todo-type'] === 'fbm' && this.data.row._embedded.productOffer.eav){
          this.currentFBA.key = this.sourceId
          this.currentFBA.label = this.data.row._embedded.productOffer.eav['integrations-amazon-offer-sku-fbm'][this.marketplace][this.sourceId]
          return this.currentFBA.label
        }
      }
    },
    getMsku (marketplace) {
      if(this.data.row?._embedded?.productOffer?.eav && this.data.row._embedded.productOffer.eav['integrations-source-id']) {
        let sourceId = this.data.row._embedded.productOffer.eav['integrations-source-id']
        this.sourceId = sourceId
      }

      if ((this.model?.eav || {})['storage-acceptance-expected-item-todo-type'] === 'fba') {
        if (this.data.row && this.data.row._embedded && this.data.row._embedded.productOffer && this.data.row._embedded.productOffer.eav && this.data.row._embedded.productOffer.eav['integrations-amazon-offer-sku-fba-list'] && marketplace) {
          this.msku = this.data.row._embedded.productOffer.eav['integrations-amazon-offer-sku-fba-list'][marketplace]
          if (Array.isArray(this.msku) && this.msku) {
            this.optionsMSKU = this.msku.map(option => {
              const key = Object.keys(option)[0]
              const value = option[key]
              return value
            })
          }

        }
      } else {
        if (this.data.row && this.data.row._embedded && this.data.row._embedded.productOffer && this.data.row._embedded.productOffer.eav && this.data.row._embedded.productOffer.eav['integrations-amazon-offer-sku-fbm-list'] && marketplace) {
          this.msku = this.data.row._embedded.productOffer.eav['integrations-amazon-offer-sku-fbm-list'][marketplace]
          if (Array.isArray(this.msku) && this.msku) {

            this.optionsMSKU = this.msku.map(option => {
              const key = Object.keys(option)[0]
              const value = option[key]
              return value
            })
          }

        }
      }
    },
    addNewOption () {
      this.newOption2.key = this.sourceId

      let shop = ''
      if (this.data.row._embedded && this.data.row._embedded.productOffer && this.data.row._embedded.productOffer._embedded && this.data.row._embedded.productOffer._embedded.virtualShop) {
        shop = this.data.row._embedded.productOffer._embedded.virtualShop._links.self.href.split('/').pop()
      } else if (this.data.row._embedded && this.data.row._embedded.productOffer && this.data.row._embedded.productOffer._embedded && this.data.row._embedded.productOffer._embedded.shop) {
        shop = this.data.row._embedded.productOffer._embedded.shop._links.self.href.split('/').pop()
      }

      if ((this.model?.eav || {})['storage-acceptance-expected-item-todo-type'] === 'fba') {
        let updatedList = []
        if (Array.isArray(this.msku) && this.msku) {
          updatedList = [...this.msku, { [this.newOption2.key]: this.newOption2.label }]
          this.$service.offer.save({ eav: { 'integrations-amazon-offer-sku-fba-list': { [this.marketplace]: updatedList } } }, this.data.row._embedded.productOffer.id, shop, {})
            .then(item => {
              this.addOptionDialog2 = false
              this.$refs.mySelect2.hidePopup()
              const updatedOptions = [...this.optionsMSKU, this.newOption2.label]
              this.optionsMSKU = updatedOptions
            })
            .then(() => {
              this.newOption2.key = ''
              this.newOption2.label = ''
              this.addOptionDialog2 = false
            })
        } else {
          this.$service.offer.save({ eav: { 'integrations-amazon-offer-sku-fba-list': { [this.marketplace]: [{ [this.newOption2.key]: this.newOption2.label }] } } }, this.data.row._embedded.productOffer.id, shop, {})
            .then(item => {
              this.addOptionDialog2 = false
              this.$refs.mySelect2.hidePopup()
              const updatedOptions = [...this.optionsMSKU, this.newOption2.label]
              this.optionsMSKU = updatedOptions
            })
            .then(() => {
              this.newOption2.key = ''
              this.newOption2.label = ''
              this.addOptionDialog2 = false
            })
        }
      } else {
        let updatedList = []
        if (Array.isArray(this.msku) && this.msku) {
          updatedList = [...this.msku, { [this.newOption2.key]: this.newOption2.label }]
          this.$service.offer.save({ eav: { 'integrations-amazon-offer-sku-fbm-list': { [this.marketplace]: updatedList } } }, this.data.row._embedded.productOffer.id, shop, {})
            .then(item => {
              this.addOptionDialog2 = false
              this.$refs.mySelect2.hidePopup()
              const updatedOptions = [...this.optionsMSKU, this.newOption2.label]
              this.optionsMSKU = updatedOptions
            })
            .then(() => {
              this.newOption2.key = ''
              this.newOption2.label = ''
              this.addOptionDialog2 = false
            })
        } else {
          this.$service.offer.save({ eav: { 'integrations-amazon-offer-sku-fbm-list': { [this.marketplace]: [{ [this.newOption2.key]: this.newOption2.label }] } } }, this.data.row._embedded.productOffer.id, shop , {})
            .then(item => {
              this.addOptionDialog2 = false
              this.$refs.mySelect2.hidePopup()
              const updatedOptions = [...this.optionsMSKU, this.newOption2.label]
              this.optionsMSKU = updatedOptions
            })
            .then(() => {
              this.newOption2.key = ''
              this.newOption2.label = ''
              this.addOptionDialog2 = false
            })
        }

      }

    },
    openBatch () {
      this.$refs.expectedWhere.open(this.data.row)
    },
    onRowDblClick () {

    },
    getRowImage(row) {
      return row._embedded.productOffer && row._embedded.productOffer.image ? row._embedded.productOffer.image : this.fallbackImage;
    },
    onImageLoadFailure (e) {
      e.target.src = 'assets/img/fallback-image/package.png'
    },
    handleEdit (menu , value , submenu) {
      console.log(value)
    },
    handleAddBatchFilter () {
      const filter = {
        type: 'jsonboperator',
        subfield: 'storage-acceptance-expected-item-ASIN',
        field: 'eav',
        value: (this.model?.eav || {})['storage-acceptance-expected-item-ASIN'],
        subtype: 'eq',
        native: true
      }
      this.$emit('add-custom-filter', filter)
    },
    handleCopy() {
      this.$emit('copy', this.data.row)
    },
    handleEditEAV (menu , value , submenu) {
      if ( this.addOptionDialog ) {
        this.addOptionDialog = false
        this.$refs.mySelect.hidePopup()
      }
      if ( this.addOptionDialog2 ) {
        this.addOptionDialog2 = false
        this.$refs.mySelect2.hidePopup()
      }
      if ( this.addOptionDialog3 ) {
        this.addOptionDialog3 = false
        this.$refs.mySelect3.hidePopup()
      }
      let data = {}
      if(submenu) {
        data = {
          [menu]: {
            ...this.model.eav,
            [submenu]: value
          }
        }
      } else {
        data = {
          [menu]: value
        }
      }
      if(menu === 'count') {
        return this.$service.acceptanceItem.save(data, this.data.row.id, 'new')
          .then(acceptanceItem => {
            this.model = _.cloneDeep(acceptanceItem)
            data = {}
          })
      }
      if (menu === 'state') {
        return this.$service.acceptanceItem.save(data, this.data.row.id)
          .then(acceptanceItem => {
            this.$emit('delete')
          })
      }
      return this.$service.acceptanceItem.save(data, this.data.row.id)
        .then(acceptanceItem => {
          this.model = _.cloneDeep(acceptanceItem)
          if (submenu === 'storage-acceptance-expected-item-todo-type' && (value === 'fba' || value === 'fbm')) {
            this.getMsku(this.marketplace)
          }
          data = {}
        })
    },
    open () {
      this.$emit('open', this.data.row)
    },
  }
}
</script>