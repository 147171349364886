<template>
  <q-tr
      :props="data"
  >
    <q-td
        key="number"
        :props="data"
        auto-width
    >
        {{ data.rowIndex + 1 }}
    </q-td>

    <q-td
        key="date"
        :props="data"
        auto-width
        style="white-space: pre-wrap !important; max-width: 100px;"
    >
        <p class="q-ma-none text-caption">
            {{ $moment(typeof data.row.created === 'object' ? data.row.created.date : data.row.created).format(appOptions.formats.fullDate) }}
        </p>
    </q-td>

    <q-td
        key="image"
        :props="data"
        auto-width
    >

      <div class="text-center">
        <img
            :src="getRowImage(data.row)"
            style="width: 80px; height: 70px; object-fit: contain;"
            @error="onImageLoadFailure"
        >
      </div>

    </q-td>
    <q-td
        key="name"
        :props="data"
        auto-width
    >
      <div class="text-center" style="width: 250px; word-wrap: break-word; white-space: normal !important;">
        <span v-if="data.row._embedded?.productOffer?.name">
          {{ data.row._embedded.productOffer.name }}
        </span>
      </div>
    </q-td>
    <q-td
        key="expectedItems"
        :props="data"
        auto-width
    >
      <div class="q-pt-md" style="min-width: 30px">
        <strong>
            {{ data.row.quantityExpected }}
        </strong>
      </div>
    </q-td>

      <q-td
          key="quantityReal"
          :props="data"
          auto-width
      >
        <div class="q-pt-md" style="min-width: 30px">
          <strong>
              {{ received }}
          </strong>
        </div>
      </q-td>

      <q-td
          key="blocked"
          :props="data"
          auto-width
      >
        <div class="q-pt-md" style="min-width: 30px">
          <strong>
              {{ blocked }}
          </strong>
        </div>
      </q-td>

      <q-td
          key="booked"
          :props="data"
          auto-width
      >
        <div class="q-pt-md" style="min-width: 30px">
          <strong>
              {{ booked }}
          </strong>
        </div>
      </q-td>

      <q-td
          key="defected"
          :props="data"
          auto-width
      >
        <div class="q-pt-md" style="min-width: 30px">
          <strong>
              {{ defected }}
          </strong>
        </div>
      </q-td>


      <q-td
          key="quantityPlace"
          :props="data"
          auto-width
      >
        <div class="q-pt-md" style="min-width: 30px">
          <strong>
              {{ onHand }}
          </strong>
        </div>
      </q-td>

      <q-td
          key="quantityShipped"
          :props="data"
          auto-width
      >
        <div class="q-pt-md" style="min-width: 30px">
          <strong>
              {{ shipped }}
          </strong>
        </div>
      </q-td>
  </q-tr>
</template>

<script>
import {mapGetters} from "vuex";
import _ from "lodash";

export default {
  name: 'ExpectedInventoryWhereTableRow',
  props: {
    data: {
      type: Object,
      required: true,
      default () {
        return null
      }
    }
  },
  data() {
    return {
      blocked: 0,
      booked: 0,
      defected: 0,
      onHand: 0,
      shipped: 0,
      received: 0,
      fallbackImage: 'assets/img/fallback-image/package.png',
    }
  },
  mounted() {
    if ( this.data.row?._embedded?.productOffer?.items && this.data.row?._embedded?.productOffer?.items.length > 0 ) {
      let items = this.data.row._embedded.productOffer.items
      items.forEach(e => {
        switch (e.state) {
          case 'new':
            this.received = e.count
            break;
          case 'booked':
            this.booked = e.count
            break;
          case 'blocked':
            this.blocked = e.count
            break;
          case 'normal':
            this.onHand = e.count
            break;
          case 'shipped':
            this.shipped = e.count
            break;
        }
      })
    }
  },
  computed: {
    ...mapGetters([
      'appOptions',
    ]),
  },
  methods: {
    getRowImage(row) {
      return row._embedded.productOffer && row._embedded.productOffer.image ? row._embedded.productOffer.image : this.fallbackImage;
    },
    onImageLoadFailure (e) {
      e.target.src = 'assets/img/fallback-image/package.png'
    },
  }
}
</script>