<template>
  <q-tr
      :props="data"
      class="clickable"
      @dblclick="onRowDblClick(data.row)"
  >
    <q-td
        key="number"
        :props="data"
        auto-width
    >
        {{ data.rowIndex + 1 }}
    </q-td>
    <q-td
        key="id"
        :props="data"
        auto-width
    >

        <div class="text-center">
            <img
                :src="getRowImage(data.row)"
                style="width: 80px; height: 70px; object-fit: contain;"
                @error="onImageLoadFailure"
            >
        </div>

        <div class="text-center">
            <q-btn
                :label="$t(model._embedded?.productOffer?.type || '')"
                style="min-width: 120px;"
                color="light-blue-9"
                icon-right="visibility"
                size="sm"
                class="q-mt-sm block full-width text-capitalize"
                no-caps
                v-if="model._embedded?.productOffer?.id"
                @click="open"
            />
        </div>
        <div class="text-center">
            <q-btn
                    :label="$t('Batch')"
                    style="min-width: 120px;"
                    color="light-blue-9"
                    size="sm"
                    class="q-my-sm block full-width text-capitalize"
                    no-caps
                    v-if="(model?.eav || {})['storage-acceptance-expected-item-ASIN']"
                    @click="handleAddBatchFilter"
            />

        </div>
    </q-td>
      <q-td
          key="name"
          :props="data"
          auto-width
      >
          <div class="text-center" style="width: 300px; word-wrap: break-word; white-space: normal !important;">
            <span v-if="model._embedded?.productOffer?.name">
              {{model._embedded.productOffer.name}}
            </span>
          </div>
          <br>
          <div v-if="model?._embedded?.warehouse" style="max-width: 300px; word-wrap: break-word; white-space: normal !important;">
              {{`${model?._embedded?.warehouse.name} (${model?._embedded?.warehouse.id})`}}
          </div>
      </q-td>


      <q-td
              key="cost"
              :props="data"
              style="width: 50px; white-space: pre-wrap !important;"
      >
          <div style="min-width: 100px">
              <editable-input-field
                      :value="model.price"
                      :label="$t('Cost')"
                      :input-label="$t('Cost')"
                      :update-function="newValue => handleEditEAV('price', newValue)"
                      :rules="[
                    (val) => /^[0-9]\d*$/.test(val),
                  ]"
              ></editable-input-field>

              <q-tooltip>
                  <div>
                      *Optional
                      <br>
                      If you are using IL, please specify the ‘Cost per Unit’ during batch creation.
                  </div>
              </q-tooltip>
          </div>

      </q-td>

      <q-td
          key="bundle"
          :props="data"
          auto-width
      >
          <div>
              <q-select
                      :model-value="(model?.eav || {})['storage-acceptance-expected-item-bundle-type']"
                      option-label="label"
                      class="q-mb-sm"
                      :options="types"
                      size="sm"
                      dense
                      stack-label
                      label="Type"
                      @update:model-value="handleEditEAV('eav', $event.id, 'storage-acceptance-expected-item-bundle-type')"
              />
              <q-tooltip>
                  <div>
                      Please select whether the items are already bundled by your supplier or not.
                      <br>
                      That way we would know we don't need to bundle your items if ASIN name has 'X-pack' of 'set of X' in its name
                  </div>
              </q-tooltip>
          </div>

        <div class="text-center" style="min-width: 200px">
            <editable-textarea-input
                    :value="(model?.eav || {})['storage-acceptance-expected-item-bundle-comment']"
                    :label="$t('Bundling Details')"
                    :outlined="true"
                    :input-label="$t('Bundling Details')"
                    :update-function="newValue => handleEditEAV('eav', newValue, 'storage-acceptance-expected-item-bundle-comment')"
            ></editable-textarea-input>
            <q-tooltip>
                <div>
                    In case we need to bundle your items into packs, please specify details here (e.g. 2-pack, 5-pack. set of 2 etc.)
                    Otherwise - input 'None'
                </div>
            </q-tooltip>

        </div>
      </q-td>



      <q-td
          key="todo"
          :props="data"
          auto-width
      >
          <div>
              <q-select
                  :model-value="(model?.eav || {})['storage-acceptance-expected-item-todo-type']"
                  option-label="label"
                  class="q-mb-sm"
                  :options="typesTodo"
                  size="sm"
                  dense
                  stack-label
                  label="Type"
                  menu-props="label"
                  @update:model-value="handleEditEAV('eav', $event.id, 'storage-acceptance-expected-item-todo-type')"
              />
              <q-tooltip>
                  <div>
                      Specify fulfilment channel. Any shipment to a specific address would be considered as FBM channel
                  </div>
              </q-tooltip>
          </div>


          <div class="text-center" style="min-width: 200px">
              <editable-textarea-input
                      :value="(model?.eav || {})['storage-acceptance-expected-item-todo-notes']"
                      :label="$t('Notes')"
                      :outlined="true"
                      :input-label="$t('Notes')"
                      :update-function="newValue => handleEditEAV('eav', newValue, 'storage-acceptance-expected-item-todo-notes')"
              ></editable-textarea-input>
              <q-tooltip>
                  <div>
                      If required, write a comment on the batch
                  </div>
              </q-tooltip>
          </div>
      </q-td>

  </q-tr>
  <q-tr
      :props="data"
      class="clickable"
      @dblclick="onRowDblClick(data.row)"
  >
      <q-td
          key="number"
          :props="data"
          auto-width
          colspan="2"
      >
          <div class="text-center">
              <editable-text-input
                      :value="(model?.eav || {})['storage-acceptance-expected-item-supplier-order-number']"
                      :label="$t('Ext Id - SON')"
                      :dense="false"
                      :input-label="$t('Ext Id - SON')"
                      :update-function="newValue => handleEditEAV('eav', newValue, 'storage-acceptance-expected-item-supplier-order-number')"
              ></editable-text-input>
          </div>

          <div class="text-center">
              <editable-text-input
                      :value="(model?.eav || {})['storage-acceptance-expected-item-supplies-il']"
                      :label="$t('Supplies IL')"
                      :dense="false"
                      :input-label="$t('Supplies IL')"
                      :update-function="newValue => handleEditEAV('eav', newValue, 'storage-acceptance-expected-item-supplies-il')"
              ></editable-text-input>
              <q-tooltip>
                <div>
                    *Optional
                    <br>
                    If you use IL, please specify  Supplier that needs to be inputted during batch creation.
                </div>
              </q-tooltip>
          </div>

          <div class="text-center">
              <form-builder :schema="schema2"/>
<!--              <editable-text-input-->
<!--                      :value="(model?.eav || {})['storage-acceptance-expected-item-purchase-date']"-->
<!--                      :label="$t('Purchase Date')"-->
<!--                      :dense="false"-->
<!--                      :input-label="$t('Purchase Date')"-->
<!--                      :update-function="newValue => handleEditEAV('eav', newValue, 'storage-acceptance-expected-item-purchase-date')"-->
<!--              ></editable-text-input>-->
<!--              <q-tooltip>-->
<!--                  <div>-->
<!--                      *Optional-->
<!--                      <br>-->
<!--                      If you use IL, please specify the Purchase Date that needs to be inputted during batch creation.-->
<!--                  </div>-->
<!--              </q-tooltip>-->

          </div>
      </q-td>

      <q-td
          key="name"
          :props="data"
          auto-width
      >
          <div class="text-center">
              <editable-text-input
                      :value="(model?.eav || {})['storage-acceptance-expected-item-ASIN']"
                      :label="$t('ASIN')"
                      :dense="false"
                      :input-label="$t('ASIN')"
                      :update-function="newValue => handleEditEAV('eav', newValue, 'storage-acceptance-expected-item-ASIN')"
              ></editable-text-input>
          </div>

          <div class="text-center">
              <editable-text-input
                      :value="(model?.eav || {})['storage-acceptance-expected-item-MSKU']"
                      :label="$t('MSKU')"
                      :dense="false"
                      :input-label="$t('MSKU')"
                      :update-function="newValue => handleEditEAV('eav', newValue, 'storage-acceptance-expected-item-MSKU')"
              ></editable-text-input>
              <q-tooltip>
                  <div>
                      *Optional
                      <br>
                      If you are using multiple SKUs for the same ASIN you can specify which one to use.
                  </div>
              </q-tooltip>
          </div>

          <div class="row text-center">
              <div class="col-5">
                <editable-text-input
                    :value="(model?.eav || {})['storage-acceptance-expected-item-upcs']"
                    :label="$t('UPCS')"
                    :dense="false"
                    :input-label="$t('UPCS')"
                    :update-function="newValue => handleEditEAV('eav', newValue, 'storage-acceptance-expected-item-upcs')"
                ></editable-text-input>
              </div>
              <div class="col-2">
                  <div class="q-pt-lg">
                      {{'/'}}
                  </div>
              </div>
              <div class="col-5">
                <editable-text-input
                    :value="(model?.eav || {})['storage-acceptance-expected-item-eans']"
                    :label="$t('EANS')"
                    :dense="false"
                    :input-label="$t('EANS')"
                    :update-function="newValue => handleEditEAV('eav', newValue, 'storage-acceptance-expected-item-eans')"
                ></editable-text-input>
              </div>
<!--              <q-input-->
<!--                  type="text"-->
<!--                  class="q-mb-xs"-->
<!--                  :dense="false"-->
<!--                  :model-value="`${(model?._embedded?.productOffer?.upcs && model._embedded.productOffer.upcs.length>0)?model._embedded.productOffer.upcs[0]: '-'} / ${(model?._embedded?.productOffer?.eans && model._embedded.productOffer.eans.length>0)?model._embedded.productOffer.eans[0]: '-'}`"-->
<!--                  :disable="true"-->
<!--                  input-class="text-center"-->
<!--                  :label="$t('UPCS/EANS')"-->
<!--              />-->

              <q-tooltip>
                  <div>
                      *Optional
                      <br>
                      If you are aware of the real barcode that will be on product package, you can specify it here.
                      <br>
                      NOTE: barcodes from Seller Central listings are not to be trusted!
                  </div>
              </q-tooltip>
          </div>
      </q-td>

      <q-td
          key="cost"
          :props="data"
          style="width: 50px; white-space: pre-wrap !important;"
      >

      </q-td>


      <q-td
          key="bundle"
          :props="data"
          auto-width
      >
          <div>
              <editable-text-input
                      :value="model.trackingNumber"
                      :label="$t('Tracking number')"
                      :dense="false"
                      :input-label="$t('Tracking number')"
                      :update-function="newValue => handleEditEAV('trackingNumber', newValue)"
              ></editable-text-input>
              <q-tooltip>
                  <div>
                      Specify inbound tracking number of the box/pallet that will arrive at our facility.
                  </div>
              </q-tooltip>
          </div>


      </q-td>

      <q-td
          key="todo"
          :props="data"
          auto-width
      >
      <span  >
        <table class="table table-valign-middle">
          <thead>
            <tr>
              <th>{{ $t('Status') }}</th>

              <th>{{ $t('Amount') }}</th>

            </tr>
          </thead>

          <tbody >
            <tr>
              <td>
                <div class="q-mt-sm">
                  <label :class="`q-py-xs q-px-sm rounded bg-positive text-caption text-white`">
                    {{ $t('Expected') }}
                  </label>
                </div>
              </td>

              <td class="text-center">

                <editable-input-field
                    :value="model.quantityExpected"
                    :label="$t('expected')"
                    :update-function="newValue => handleEditEAV('count', newValue)"
                    :rules="[
                      (val) => /^[0-9]\d*$/.test(val),
                    ]"
                ></editable-input-field>
                <q-tooltip>
                  <div>
                      Specify the quantity of units sent to our location.
                      <br>
                      NOTE: if you order single units that needs to be bundled - specify SINGLE units quantity, NOT bundle quantity!
                  </div>
                 </q-tooltip>

              </td>

            </tr>
            <tr>
              <td>
                <label :class="`q-py-xs q-px-sm rounded bg-primary text-caption text-white`">
                  {{ $t('Received') }}
                </label>
              </td>

              <td>
                <strong>{{ model.quantityReal || 0}}</strong>
              </td>

            </tr>
            <tr>
              <td>
                <label :class="`q-py-xs q-px-sm rounded bg-dark text-caption text-white`">
                  {{ $t('Allocated') }}
                </label>
              </td>

              <td>
                <strong>{{ model.quantityPlace || 0}}</strong>
              </td>

            </tr>
          </tbody>
        </table>
      </span>
      </q-td>

  </q-tr>
</template>

<script>
import _ from 'lodash'
import EditableInputField from "@/apps/app/components/delivery-services/EditableInputField.vue";
import EditableTextareaInput from "@/apps/app/components/delivery-services/EditableTextareaInput.vue";
import EditableTextInput from "@/apps/app/components/delivery-services/EditableTextInput.vue";

export default {
  name: 'ExpectedItemsRow',
  components: {
    EditableTextInput,
    EditableTextareaInput,
    EditableInputField
  },
  emits: ['open', 'add-custom-filter'],
  props: {
    data: {
      type: Object,
      required: true,
      default () {
        return null
      }
    }
  },
  data() {
    return {
      fallbackImage: 'assets/img/fallback-image/package.png',
      marketplace: '',
      bundles: '',
      todo: [],
      model: this.data.row,
      types: [
        { id: 'pre_bundling', label: 'Pre Bundling' },
        { id: 'for_bundling', label: 'For Bundling' },
      ],
      typesTodo: [
        { id: 'fba', label: 'FBA' },
        { id: 'fbm', label: 'FBM' },
        { id: 'return', label: 'Return' },
        { id: 'disposal', label: 'Disposal'},
        { id: 'other', label: 'Other'}
      ],
    }
  },
  computed: {
    schema2 () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'date',
                label: this.$t('External date'),
                field: 'extDate',
                value: (this.model?.eav || {})['storage-acceptance-expected-item-purchase-date'],
                class: 'bg-white',
                dense: false,
                styleClasses: 'bg-white',
                wrapperStyleClasses: 'col-12 q-pa-xs',
                get (extDate) {
                  return extDate && typeof extDate === 'object'
                    ? extDate.date
                    : extDate
                },
                onChange: (extDate) => {
                  this.hasChange = true
                  this.handleEditEAV('eav', extDate, 'storage-acceptance-expected-item-purchase-date')
                }
              },
            ]
          }
        ]
      }
    },
    schema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'tag',
                label: this.$t('Type'),
                wrapperStyleClasses: 'col-12 col-sm-12 q-pa-xs',
                field: 'todo',
                value: this.todo,
                onSubmit: (value) => {
                  if (!value) {
                    return
                  }

                  if (this.acceptance && this.acceptance.docExtended) {
                    this.updateAcceptance({ docExtended: [...this.acceptance.docExtended, value] })

                    this.$emit('change', this.acceptance)
                  }
                },
                onRemove: (value) => {
                  this.updateAcceptance({ docExtended: this.acceptance.docExtended.filter(x => x !== value) })
                  this.$emit('change', this.acceptance)
                },
                btnStyleClasses: 'q-pl-sm'
              }
            ]
          }
        ]
      }
    }
  },
  watch: {
    data: {
      handler (value) {
        this.model = this.data.row
      },
      deep: true
    },
  },
  mounted() {
    this.marketplace = this.data.row?._embedded?.productOffer?.eav['integrations-amazon-offer-marketplace'] ? this.data.row._embedded.productOffer.eav['integrations-amazon-offer-marketplace'] : 'ATVPDKIKX0DER'
    this.model = _.cloneDeep(this.data.row)
  },
  methods: {
    onRowDblClick () {

    },
    getRowImage(row) {
      return row._embedded.productOffer && row._embedded.productOffer.image ? row._embedded.productOffer.image : this.fallbackImage;
    },
    onImageLoadFailure (e) {
      e.target.src = 'assets/img/fallback-image/package.png'
    },
    handleEdit (menu , value , submenu) {
      console.log(value)
    },
    handleAddBatchFilter () {
      const filter = {
        type: 'jsonboperator',
        subfield: 'storage-acceptance-expected-item-ASIN',
        field: 'eav',
        value: (this.model?.eav || {})['storage-acceptance-expected-item-ASIN'],
        subtype: 'eq',
        native: true
      }
      this.$emit('add-custom-filter', filter)
    },
    handleEditEAV (menu , value , submenu) {
      let data = {}
      if(submenu) {
        data = {
          [menu]: {
            ...this.model.eav,
            [submenu]: value
          }
        }
      } else {
        data = {
          [menu]: value
        }
      }
      if(menu === 'count') {
        return this.$service.acceptanceItem.save(data, this.data.row.id, 'new')
          .then(acceptanceItem => {
            this.model = _.cloneDeep(acceptanceItem)
            data = {}
          })
      }
      return this.$service.acceptanceItem.save(data, this.data.row.id)
        .then(acceptanceItem => {
          this.model = _.cloneDeep(acceptanceItem)
          data = {}
        })
    },
    open () {
      this.$emit('open', this.data.row)
    },
  }
}
</script>