<template>
  <div>
    <q-input
        type="text"
        v-model="inputValue"
        class="text-center"
        :label="inputLabel"
        :dense="dense"
        :disable="disabled"
        input-class="text-center"
        :class="{ 'bg-light-blue-3': hasChange }"
        autofocus
        @focus="onFocus"
        @blur="onFocusOut"
        @update:model-value="updateValue"
    >
    </q-input>
  </div>
</template>

<script>
export default {
  name: 'EditableTextInput',
  emits: ['change-catch-all'],
  props: {
    label: String,
    value: [String, Number],
    inputLabel: String, // Label for the input field
    updateFunction: Function,
    resetChange: Boolean,
    dense: Boolean,
    outlined: {
      type: Boolean,
      default () {
        return false
      }
    },
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
  },
  watch: {
    value: {
      handler (value) {
        this.inputValue = value

        if (this.inputValue === value) {
          this.hasChange = false
        }
      }
    },
    resetChange: {
      handler (value) {
        if (value === true) {
          this.hasChange = false
        }
      },
    },
  },
  mounted () {
    this.inputValue = this.value
  },
  data () {
    return {
      inputValue: null,
      hasChange: false
    }
  },
  methods: {
    updateValue (newValue) {
      if (newValue !== this.value) {
        this.hasChange = true
      } else {
        this.hasChange = false
      }
    },
    onFocus () {
      setTimeout(() => {
        this.$emit('change-catch-all', false)
      }, 10)
    },
    onFocusOut () {
      if (this.hasChange) {
        this.updateFunction(this.inputValue)
      }

      this.$emit('change-catch-all', true)
    },
  },
}
</script>